<template>
  <div class="login">
    <div class="logo">
      <el-image fit="fill" :src="logoImage" alt=""></el-image>
      <span class="language" @click="switchLang()">{{ isChinese ? 'EN' : '中文' }}</span>
    </div>
    <div class="account">
      <div class="register-title" v-if="!isLogin">
        <div class="title">{{ $t('registerNewUser') }}</div>
      </div>
      <div class="login-title" v-if="isLogin">
        <div class="title">
          {{ $t('companyName') }}
        </div>
        <div class="text">
          {{ $t('welcomeLogin') }}
        </div>
      </div>
      <el-form ref="user-form" :model="form" label-width="80px">
        <el-form-item prop="email" label="">
          <el-input v-model="form.email" :placeholder="$t('emailPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="">
          <el-input v-model="form.password" type="password" :placeholder="$t('passwordPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="" v-if="!isLogin">
          <el-input v-model="form.confirmPassword" type="password" :placeholder="$t('confirmPasswordPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">
            {{ isLogin ? $t('login') : $t('register') }}
          </el-button>
        </el-form-item>
        <div class="alert" v-if="!isLogin" @click="toggleLogin(true)">
          {{ $t('useAccount') }}
        </div>
        <div class="alert" v-if="isLogin">
          <div class="left" @click="toggleLogin(false)">
            {{ $t('newRegister') }}
          </div>
          <div class="forget">
            {{ $t('forgetPassword') }}
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import ApiService from '@/api/apiService';
import { sha256 } from 'js-sha256';
import API from '@/api';

export default {
  name: 'login',
  data() {
    return {
      isLogin: true,
      verifiyImage: '',
      form: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      logoImage: require('@/static/images/logo.png')
    };
  },
  components: {},
  computed: {
    isChinese() {
      return this.$store.state.isChinese;
    }
  },
  watch: {},
  mounted() {
    this.isLogin = true;
    // this.getVerifiyImage();
  },
  methods: {
    async getVerifiyImage() {
      await ApiService.get('/verificationCode/getBase64Image');
    },
    async onSubmit() {
      const { email, password, confirmPassword } = this.form;
      const api = this.isLogin ? API.login : API.register;
      const result = await ApiService.post(api, {
        email,
        password: sha256(password),
        confirmPassword,
        verifyToken: '',
        code: ''
      });
      if (this.isLogin) {
        localStorage.setItem('userId', result.data.loginUserVo.id);
        localStorage.setItem('token', result.data.token);
        this.$router.push('/home');
      } else {
        console.log('this.$refs["user-form"]', this.$refs['user-form']);
        this.$refs['user-form'].resetFields();
        this.$message.success('注册成功，请登录！');
        this.isLogin = true;
        // this.$refs["user-form"].resetFields();
      }
    },
    toggleLogin(flag) {
      this.isLogin = flag;
    },
    switchLang() {
      this.$store.dispatch('changeLanguage');
      this.$i18n.locale = this.$store.state.lang;
      this.logoImage = this.isChinese ? require('@/static/images/logo.png') : require('@/static/images/en-logo.png');
    }
  }
};
</script>

<style scoped lang="less">
.login {
  background-color: #1a2043;
  height: 100vh;
  color: #fff;

  .alert {
    cursor: pointer;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14vw;
    .el-image {
      width: 17.22vw;
    }
    .language {
      font-size: 1.3vw;
      font-weight: 500;
      line-height: 1.7vw;
    }
  }
  .account {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 25vw;
    // padding-left: 50%;
    // transform: translateX(-50%);
    // .register-title {
    //   margin: 100px 0 50px 0;
    // }
    .register-title,
    .login-title {
      // margin: 100px 0 30px -120px;
      margin: 7vw 0 3.5vw 0;
      // transform: translateX(-50%);
      .title {
        text-align: left;
        font-size: 1.8vw;
        font-weight: 500;
        color: #eee7d8;
        margin-bottom: 1.4vw;
      }
      .text {
        text-align: left;
        font-size: 1.1vw;
        font-weight: 400;
        color: #eee7d8;
      }
    }
    .el-form {
      width: 25vw;
      margin-left: -5.55vw;
      .el-button {
        display: inline-block;
        width: 100%;
        background: #eee7d8;
        color: #1a2043;
        border: unset;
        &:hover {
          background: #b5a687;
          color: #fff;
        }
      }
      .alert {
        padding-left: 5.55vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 25vw;
        box-sizing: border-box;
        .left {
          text-align: left;
        }
      }
    }
  }
}

/deep/.el-message-box__wrapper {
  .el-button {
    width: 100%;
    display: inline-block;
  }
}
</style>
